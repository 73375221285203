import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2010 Mensajes" />
      <h1>2010 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2010, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.{' '}
      </p>
      <h2>2010 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>03/01/10</td>
            <td>Jason Henderson </td>
            <td>La Mentalidad Del Antiguo Pacto &nbsp;</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100103APMentalidad.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>10/01/10</td>
            <td>Jason Henderson </td>
            <td>La Salvación Es La Penitud de Cristo </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100110PlenitudDeCristo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>17/01/10</td>
            <td>Jason Henderson </td>
            <td>Nuevo Nacimiento 1 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100117NuevoNacimiento01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>24/01/10</td>
            <td>Jason Henderson </td>
            <td>Nuevo Nacimiento 2 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100124NuevoNacimiento02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>07/02/10</td>
            <td>Jason Henderson </td>
            <td>Cómo Experimentamos La Salvación </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100207ExperimentarLaSalvacion.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/02/10</td>
            <td>Jason Henderson </td>
            <td>Fe 1 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100214Fe01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>21/02/10</td>
            <td>Jason Henderson </td>
            <td>Fe 2 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100221Fe02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>29/02/10</td>
            <td>Barbara Samuel </td>
            <td>Oir, Ver, Conocer </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100229OirVerConocer.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>07/03/10</td>
            <td>Jason Henderson </td>
            <td>Fe 3 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100307Fe03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/03/10</td>
            <td>Jason Henderson </td>
            <td>Fe 4 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100314Fe04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>21/03/10</td>
            <td>Jason Henderson </td>
            <td>Fe 5 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100321Fe05.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>11/04/10</td>
            <td>Jason Henderson </td>
            <td>Provisión y Propósito 1 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100411ProvProp01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>18/04/10</td>
            <td>Jason Henderson </td>
            <td>Provisión y Propósito 2 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100418ProvProp02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/04/10</td>
            <td>Jason Henderson </td>
            <td>Provisión y Propósito 3 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100425ProvProp03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>02/05/10</td>
            <td>Jason Henderson </td>
            <td>Provisión y Propósito 4 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100502ProvProp04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>09/05/10</td>
            <td>Jason Henderson </td>
            <td>El Orden de La Comprensión de La Salvación 1 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100509OrdenDeSalvacion01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>16/05/10</td>
            <td>Jason Henderson </td>
            <td>El Orden de La Comprensión de La Salvación 2 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100516OrdenDeSalvacion02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>23/05/10</td>
            <td>Jason Henderson </td>
            <td>El Orden de La Comprensión de La Salvación 3 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100523OrdenDeSalvacion03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>30/05/10</td>
            <td>Jason Henderson </td>
            <td>El Orden de La Comprensión de La Salvación 4 </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100530OrdenDeSalvacion04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>06/06/10</td>
            <td>Jason Henderson </td>
            <td>Advertencia</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100606Advertencia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>13/06/10</td>
            <td>Barbara Samuel </td>
            <td>La Casa de Dios </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100613LaCasaDeDios.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/06/10</td>
            <td>Barbara Samuel </td>
            <td>La Vista Espiritual </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100620LaVistaEspiritual.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>27/06/10</td>
            <td>Juan Manuel Alvarez</td>
            <td>Poned La Mira En Las Cosas De Arriba </td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100627PonedLaMiraArriba.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>04/07/10</td>
            <td>Jason Henderson </td>
            <td>Expectativa</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100704Expectativa.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>11/07/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 1 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/mp3/01_La_Iglesia_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100709Iglesia01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>18/07/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 2 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/mp3/02_La_Iglesia_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100718Iglesia02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/07/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 3 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/mp3/03_La_Iglesia_03.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100725Iglesia03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>01/08/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 4 </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/mp3/04_La_Iglesia_04.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100801Iglesia04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>08/08/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 5 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100808Iglesia05.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>15/08/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 6 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100815Iglesia06.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/08/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 7 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100822Iglesia07.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>29/08/10</td>
            <td>Jason Henderson </td>
            <td>La Iglesia 8 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100829Iglesia08.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>12/09/10</td>
            <td>Grace Montero S </td>
            <td>La Iglesia 9 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100912Iglesia09.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>19/09/10</td>
            <td>Jason Henderson </td>
            <td>El Amor 1 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100919Amor01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>26/09/10</td>
            <td>Jason Henderson </td>
            <td>El Amor 2 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/100926Amor02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>03/10/10</td>
            <td>Ben Farmer </td>
            <td>El Propósito Del Alma </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101003PropositoDelAlma.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>17/10/10</td>
            <td>Jason Henderson </td>
            <td>El Amor 3 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101017Amor03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>24/10/10</td>
            <td>Jason Henderson </td>
            <td>Terminar La Carrera </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101024TerminarLaCarrera.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>31/10/10</td>
            <td>Jason Henderson </td>
            <td>La Suficiencia de La Gracia </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101031SuficienciaDeLaGracia.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>07/11/10</td>
            <td>Grace Montero </td>
            <td>...Pero Vivos Para Dios </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101107PeroVivosParaDios.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/11/10</td>
            <td>Jason Henderson </td>
            <td>Santificación 1 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101114Santificacion01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>21/11/10</td>
            <td>Jason Henderson </td>
            <td>Santificación 2 </td>
            <td>&nbsp;</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/10/pdf/101121Santificacion02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="70">28/11/10</td>
            <td width="130">Jason Henderson </td>
            <td>Santificación 3 </td>
            <td width="50">&nbsp;</td>
            <td width="40">
              <div align="center">
                <a href={asset('mensajes/10/pdf/101128Santificacion03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
